<template>
	<div>
		<div class="sidebar-main">
			<div class="sidebar-login">
				<div class="sidebar-login-item" v-if="!isLogin">
					<img src="../../assets/no-login-img.png" class="no-imgs" />
					
				</div>
				<div class="sidebar-login-user" v-if="isLogin">
					<div class="login-user-item">
						<img :src="userData.avatar" class="login-user-img"/>
						<img src="../../assets/bizhi-vip-icon.png" class="vip-icons"/>
					</div>
				</div>
				
				<div class="login-item-text gestures" @click="changeLoginShow" v-if="!isLogin">登录丨注册</div>
				<div class="login-item-text" v-if="isLogin">{{userData.username || userData.nickname}}</div>
				<div class="login-item-tips" v-if="!isLogin">登录可获得等级勋章</div>
				<div class="cat-food-item" v-if="isLogin">
					<span class="cat-food-text gestures" v-if="vipData.status != 1">开通会员</span>
					<div class="cat-food-text" v-if="vipData.status == 1 && vipData.permanent==1">永久会员</div>
					<div class="cat-food-text" v-if="vipData.status == 1 && vipData.permanent!=1">会员到期</div>
					<div class="cat-food-text" v-if="vipData.status == 1 && vipData.permanent!=1">{{vipData.expiry}}</div>
					<!-- <span class="cat-food-text" v-if="vipData.status == 1">{{vipData.permanent==1 ? '永久会员' : `会员到期:${vipData.expiry}`}}</span> -->
				</div>
			</div>
			<div class="sidebar-tab-list gestures" :class="{'sidebar-tab-sel':sidebarIndex==index}"
				v-for="(value,index) in sidebarList" :key="index" @click="changeTab(value,index)">
				<img :src="sidebarIndex==index?value.imgs:value.img" class="sidebar-tab-img" />
				<div class="sidebar-tab-text">{{value.text}}</div>
				<div class="sidebar-tab-line" v-if="sidebarIndex==index"></div>
			</div>
			
			
		</div>
		<el-dialog
		  title=""
		  :visible.sync="loginShow"
		  width="380px"
		  @close="loginClose"
		  :show-close="false">
			<div class="register-main">
				<div class="register-line"></div>
				<div class="register-navs">
					<div>登录</div>
					<img src="../../assets/login-close-img.png" class="gestures" @click="loginShow = false"/>
				</div>
				<div class="register-tabs">
					<div class="register-tab-item gestures" :class="{'tab-sels': tabIndex === index}" 
					v-for="(value,index) in tabList" :key="index" @click="changeLogin(index)">
						{{value.text}}
					</div>
				</div>
				<div style="padding: 15px 20px 0px 20px;" v-show="tabIndex == 1">
					<div class="register-textbox">
						<img src="../../assets/login-phone-icon.png" class="phone-imgs"/>
						<input placeholder="请输入手机号" class="textbox-val" style="flex: 1;" v-model="phone"/>
					</div>
					<div class="register-textbox">
						<img src="../../assets/login-code-icon.png" class="phone-imgs"/>
						<input placeholder="请输入验证码" class="textbox-val" v-model="code"/>
						<div class="obtain-code gestures" @click="getCode">{{codename}}</div>
					</div>
					
					<div class="login-btns" @click="login" v-loading="loading">登录</div>
				</div>
				
				<div style="padding: 15px 20px 0px 20px;" v-show="tabIndex == 0">
					<div class="wechat-code-main">
						<div class="wechat-code-item">
							<img :src="codeUrl"/>
							<div class="overtime-item" v-if="isOverdue">二维码已失效，<span class="overtime-item-text gestures" @click="reacquireCode">重新获取</span></div>
						</div>
					</div>
					<div class="wechat-code-text">微信扫一扫登录</div>
				</div>
				<div class="register-boom-other">
					<div class="boom-other-line"></div>
					<div style="margin: 0px 12px;">其他登录方式</div>
					<div class="boom-other-line"></div>
				</div>
				<div class="other-login">
					<img src="../../assets/login-qq-icon.png" class="gestures"/>
				</div>
			</div>
		</el-dialog>
	</div>
	
</template>

<script>
	import {sendCode,phoneLogin,userInfo,bingUserInfo} from "@/api/index.js"
	import { getToken,removeToken,setToken } from '@/utils/auth'
	import login from "../../../csdPublic/login"
	import { getUserInfo, getVipInfo, getPoint } from "../../../csdPublic/user"
	import { getStorage, setStorage, removeStorage } from "../../../csdPublic/utils/storage"
	export default {
		data() {
			return {
				sidebarList: [{
						text: '动态壁纸',
						img: require('../../assets/sidebar/dongtai.png'),
						imgs: require('../../assets/sidebar/dongtai-sel.png'),
						path:'home',
						isLogin:false
					},
					{
						text: '静态壁纸',
						img: require('../../assets/sidebar/jingtai.png'),
						imgs: require('../../assets/sidebar/jingtai-sel.png'),
						path:'staticState',
						isLogin:false
					},
					{
						text: '作者推荐',
						img: require('../../assets/sidebar/zuoze.png'),
						imgs: require('../../assets/sidebar/zuoze-sel.png'),
						path:'authorRecommend',
						isLogin:false
					},
					// {text:'桌面助手',img:require('../../assets/sidebar/zhuomian.png'),imgs:require('../../assets/sidebar/zhuomian-sel.png')},
					{
						text: '个人中心',
						img: require('../../assets/sidebar/geren.png'),
						imgs: require('../../assets/sidebar/geren-sel.png'),
						path:'personal',
						isLogin:true
					},
				],
				sidebarIndex: 0,
				loginShow: false,
				tabList: [
					{text:'微信登录'},
					{text:'短信登录'}
				],
				tabIndex: 0,
				phone: '',
				code: '',
				loading:false,
				userData:{},
				isLogin:false,
				codeUrl:'',
				isOverdue:false,
				vipData:{},
				codeDisabled:false,
				codename:'获取验证码',
				count:60,
				menuInfo:{},
				preLogin:false,
				menuIndex:0
			}
		},
		watch:{
			//监听路由
			$route:{
				handler(to, from) {
					if(to){
						console.log(to)
						let index = this.sidebarList.findIndex(item=>item.path == to.name)
						console.log(index)
						if(index>-1){
							this.sidebarIndex = index
						}
					}
				},
				deep: true,
				immediate: true,
				uuid:''
			},
			'$store.state.userInfo':{
				handler(val) {
					if(val){
						this.userData = val
					}
				}
			},
			'$store.state.vipInfo':{
				handler(val) {
					if(val){
						this.vipData = val
					}
				}
			},
		},
		created() {
			if(getStorage("token")){
				this.requestUserInfo()
				// this.getUserInfo()
			}
		},
		methods: {
			loginClose(){
				login.stopInterval()
			},
			async changeLoginShow(){
				// 获取登录二维码
				const res = await login.getLoginCode()
				console.log(res)
				this.codeUrl = res.codeUrl
				this.loginShow = true
				this.reacquireCode()
				
			},
			// 重新轮询登录状态
			async reacquireCode(){
				try {
				  const poll = await login.loginPoll()
				  console.log(poll)
				  this.requestUserInfo(1)
				} catch (e) {
				  if (e.code === 1000){
					  console.log("二维码已过期")
				  } 
				  console.log(e.msg)
				}
			},
			// 发送验证码
			async getCode(){
				if(this.codeDisabled){
					return false
				}
				if(this.phone==''){
					this.showToast('请输入手机号','updateImg noIcon')
					return false
				}
				let reg = /^(13|14|15|16|17|18|19)[0-9]{9}$/;
				if(!reg.test(this.phone)){
					this.showToast('手机号码格式不正确','updateImg noIcon')
					return false
				}
				try {
				  await login.getPhoneVerifyCode(this.phone)
				  this.showToast('验证码发送成功','updateImg noIcon')
				  this.counts()
				} catch (e) {
				  this.showToast(e.msg,'updateImg noIcon')
				}
				
			},
			//验证码倒计时事件
			counts(){
				const countDown = setInterval(() => {
				    if (this.count <= 0) {
						this.count = 60,
						this.codename='获取验证码',
						this.codeDisabled = false
				        clearInterval(countDown)
				        return
				    }
				    this.count--
					this.count = this.count,
					this.codename = this.count < 10 ? `0${this.count}s` : `${this.count}s`,
					this.codeDisabled = true
				},1000);
			},
			// 登录
			async login() {
				if(this.phone==''){
					this.showToast('请输入手机号','updateImg noIcon')
					return false
				}
				let reg = /^(13|14|15|16|17|18|19)[0-9]{9}$/;
				if(!reg.test(this.phone)){
					this.showToast('手机号码格式不正确','updateImg noIcon')
					return false
				}
				if(this.code==''){
					this.showToast('请输入验证码','updateImg noIcon')
					return false
				}
				this.loading = true
				try {
				  const data = await login.phoneLogin(this.phone, this.code)
				  setStorage('token',data.token)
				  this.requestUserInfo(1)
				} catch (e) {
				  this.loading = false
				  this.showToast(e.msg,'updateImg noIcon')
				}
			},
			// 获取用户信息
			async requestUserInfo(type = 0){
				try {
				  // 获取用户信息
				  const userInfo = await getUserInfo()
				  this.isLogin = true
				  this.userData = userInfo
				  this.saveUserData(userInfo)
				  this.$store.commit('saveUserInfo',userInfo)
				  setStorage('userInfo',JSON.stringify(userInfo))
				  // 获取 Vip 信息
				  const vipInfo = await getVipInfo()
				  this.$store.commit('saveVipInfo',vipInfo)
				  setStorage('vipInfo',JSON.stringify(vipInfo))
				  this.vipData = vipInfo
				  if(type == 1){
				  	this.loginShow = false
					this.showToast('登录成功','updateImg noIcon')
					this.loading = false
				  }
				  if(this.preLogin){
					  this.sidebarIndex = this.menuIndex
					  this.$router.push({ name: this.menuInfo.path});
					  this.preLogin = false
				  }
				} catch (e) {
				  if (e.code == 403) removeStorage("token")
				  console.log(e.msg)
				}
			},
			changeTab(data, index) {
				if (this.sidebarIndex != index) {
					if(data.isLogin && !this.isLogin){
						this.changeLoginShow()
						this.menuInfo = data
						this.preLogin = true
						this.menuIndex = index
						return false
					}
					this.sidebarIndex = index
					this.preLogin = false
					this.$router.push({ name: data.path});
					
				}
			},
			saveUserData(data){
				let prm = {
					avatar:data.avatar,
					nickname:data.nickname,
					uuid:data.uid,
					bindEmail:data.bind_email,
					bindPhone:data.bind_phone,
					
				}
				// bingUserInfo(prm).then(res=>{
					
				// })
			},
			changeLogin(index){
				if (this.tabIndex != index) {
					if(index==1){
						login.stopInterval()
					}else{
						this.changeLoginShow()
					}
					this.tabIndex = index
				}
			},
			showToast(title,classText){
				this.$message({
				    message: title,
					customClass:classText,
					iconClass:'el-icon-success',
					offset:80,
				});
			},
		}
	}
</script>

<style lang="scss">
	.overtime-item{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.6);
		display: flex;
		align-items: center;
		justify-content: center;
		color: #FFFFFF;
		font-size: 13px;
		.overtime-item-text{
			color: #20B7E3;
		}
	}
	.cat-food-item{
		// display: flex;
		// align-items: center;
		// justify-content: center;
		text-align: center;
		margin-top: 5px;
		img{
			width: 18px;
			height: 16px;
		}
		.cat-food-text{
			margin-left: 5px;
			color: #808080;
			font-size: 12px;
		}
		.cat-food-val{
			color: #FFC44E;
			font-size: 12px;
			font-weight: bold;
		}
	}
	.sidebar-login-user{
		display: flex;
		justify-content: center;
		.login-user-item{
			width: 70px;
			height: 70px;
			position: relative;
			.login-user-img{
				width: 70px;
				height: 70px;
				border-radius: 50%;
			}
			.vip-icons{
				position: absolute;
				width: 27px;
				height: 27px;
				right: -4px;
				top: 0;
			}
		}
	}
	.updateImg{
		background-color: #0EAAF3;
		z-index: 999999;
		min-width: 0px!important;
		border: 1px solid #20B7E3!important;
		color: #0EAAF3!important;
		padding: 8px 12px!important;
	}
	.noIcon .el-message__icon{
		display:none!important;
	}
	.noIcon .el-icon-success{
		display:none!important;
	}
	.el-message--info .el-message__content{
		color: #FFFFFF!important;
	}
	.el-message .el-icon-success{
		color: #159A68!important;
		font-size: 20px!important;
		/* display: none; */
		margin-right: 6px;
	}
	.wechat-code-text{
		text-align: center;
		padding-top: 12px;
		color: #0EAAF3;
		font-size: 16px;
	}
	.wechat-code-main{
		display: flex;
		justify-content: center;
		.wechat-code-item{
			border: 1px solid #E2E2E2;
			padding: 13px;
			position: relative;
			img{
				width: 176px;
				height: 176px;
			}
		}
	}
	.other-login{
		text-align: center;
		padding-top: 16px;
		img{
			width: 36px;
			height: 36px;
		}
	}
	.register-boom-other{
		padding-top: 68px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #999999;
		font-size: 14px;
		.boom-other-line{
			width: 56px;
			height: 1px;
			background-color: #B3B3B3;
		}
	}
	.register-main{
		padding-bottom: 36px;
	}
	.login-btns{
		height: 46px;
		line-height: 46px;
		text-align: center;
		background: #0EAAF3;
		border-radius: 4px;
		color: #FFFFFF;
		font-size: 16px;
		margin-top: 20px;
	}
	.register-textbox{
		margin-top: 15px;
		display: flex;
		align-items: center;
		border-radius: 4px;
		border: 1px solid #D2D2D2;
		padding: 15px;
		.phone-imgs{
			width: 20px;
			height: 22px;
		}
		.textbox-val{
			font-size: 14px;
			margin-left: 12px;
		}
		.obtain-code{
			margin-left: auto;
			color: #0EAAF3;
			font-size: 14px;
		}
	}
	.register-tabs{
		display: flex;
		align-items: center;
		padding: 22px 40px 0px 40px;
		.register-tab-item{
			width: 50%;
			text-align: center;
			padding-bottom: 6px;
			color: #8A9297;
			font-size: 16px;
		}
		.tab-sels{
			color: #0EAAF3;
			border-bottom: 2px solid #0EAAF3;
		}
	}
	.register-navs{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 13px 0px 22px;
		color: #303030;
		font-size: 16px;
		font-weight: bold;
		img{
			width: 20px;
			height: 20px;
		}
	}
	.register-line{
		height: 6px;
		background-color: #0EAAF3;
	}
	.el-dialog__body{
		padding: 0!important;
		background-color: #FFFFFF!important;
		border-radius: 6px!important;
	}
	.el-dialog__header{
		padding: 0!important;
	}
	.el-dialog{
		background: #FFFFFF!important;
		border-radius: 6px!important;
	}
	.sidebar-main {
		position: fixed;
		top: 72px;
		left: 0;
		bottom: 0;
		background-color: #2D3135;
		width: 180px;
		z-index: 999;
	}

	.sidebar-login {
		padding-top: 40px;
		padding-bottom: 25px;

		.sidebar-login-item {
			text-align: center;

			.no-imgs {
				width: 70px;
				height: 70px;
			}
		}

		.login-item-text {
			text-align: center;
			padding-top: 10px;
			color: #D5D5D6;
			font-size: 12px;
		}

		.login-item-tips {
			text-align: center;
			padding-top: 5px;
			color: #808080;
			font-size: 12px;
		}
	}

	.sidebar-tab-list {
		margin-top: 10px;
		display: flex;
		align-items: center;
		position: relative;
		height: 42px;
		padding-left: 26px;

		.sidebar-tab-img {
			width: 16px;
			height: 16px;
		}

		.sidebar-tab-text {
			margin-left: 6px;
			color: #8A9297;
			font-size: 14px;
		}
	}

	.sidebar-tab-sel {
		background-color: #3F4347;

		.sidebar-tab-text {
			color: #0EAAF3;
		}
	}

	.sidebar-tab-line {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 4px;
		background-color: #0EAAF3;
	}
</style>