import http from "../utils/axios";
import config from "../config";

// 获取用户信息
const getUserInfoApi = (params) => {
  return http.get(`${config.qnwProjectDomain}/api/mem_profile`, params);
};

// 获取VIP信息
const getVipInfoApi = (params) => {
  return http.get(`${config.qnwProjectDomain}/api/license`, params);
};

export { getUserInfoApi, getVipInfoApi };
