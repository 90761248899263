import request from '@/utils/request'

// 壁纸分类
export function wallpaperClassify() {
  return request({
    url: '/apis/wallpaperClassify',
    method: 'get',
  })
}

// 今日热搜壁纸
export function hotBotWallpaper() {
  return request({
    url: '/apis/hotBotWallpaper',
    method: 'get',
  })
}

// 壁纸列表
export function wallpaperList(prm) {
  return request({
    url: `/apis/wallpaper?${prm}`,
    method: 'get',
  })
}

// 获取用户排名列表
export function rankingUserList(date) {
  return request({
    url: `/apis/user/rankingUserList?periodsName=${date}`,
    method: 'get',
  })
}

// 排行周期列表
export function rankingPeriodList() {
  return request({
    url: '/apis/rankingPeriodList',
    method: 'get',
  })
}

// 发送验证码
export function sendCode(phone) {
  return request({
    url: `/apis/sendVerificationCode?phone=${phone}`,
    method: 'get',
  })
}

// 手机验证码登录
export function phoneLogin(data) {
  return request({
    url: '/apis/phoneLogin',
    method: 'post',
	data,
  })
}

// 获取用户信息
export function userInfo() {
  return request({
    url: '/apis/user/userinfo',
    method: 'get',
  })
}




//  保存用户信息
export function bingUserInfo(data) {
  return request({
    url: '/apis/bingUserInfo',
    method: 'post',
	data,
  })
}