const jsonJoin = (data) => {
  let str = ""
  for (const [key, value] of Object.entries(data)) {
    const arr = [null, undefined]
    if (!arr.includes(value)) {
      if (str !== "") str += "&"
      str += `${key}=${value}`
    }
  }
  return str
}

export default jsonJoin
