import axios from 'axios'
import { getToken,removeToken } from '@/utils/auth'
import { Notification, Message } from 'element-ui'
import { getStorage, setStorage, removeStorage } from "../../csdPublic/utils/storage"
axios.defaults.headers['Content-Type'] = 'application/json'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  if (getStorage('token')) {
	let urls = ''
	let userData = JSON.parse(getStorage('userInfo'))
	if (config.url.indexOf("?") != -1){
		urls = `${config.url}&userId=${userData.uid}`
	}else{
		urls = `${config.url}?userId=${userData.uid}`
	}
    config.headers['Authorization'] = getToken()// 让每个请求携带自定义token 请根据实际情况自行修改
	config.url = urls
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
	// console.log(res)
    // 获取错误信息
    const msg = res.data.msg
    if (code === 500) {
      Message({
        message: msg,
        type: 'error'
      })
      return Promise.reject(new Error(msg))
    }else if (code !== 200) {
      Notification.error({
        title: msg
      })
      return Promise.reject(res.data)
    } else {
      return res.data
    }
  },
  error => {
    // console.log(error.response)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
	if(error.response.data.code==403){
		removeToken()
	}
    // Message({
    //   message: message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error.response)
  }
)

export default service
