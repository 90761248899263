// 生成一个ekey
const getEkey = () => {
  const letters1 = ["a", "d", "g", "h", "k", "o", "4", "5", "6", "7", "8"]
  const letters2 = [
    "0",
    "1",
    "2",
    "3",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "u",
    "i",
    "o",
    "p",
    "m",
    "n",
  ]
  let s = ""
  s += Math.floor(Math.random() * 3000) + 7000 // 随机生成符合前4位的数
  s += letters1[Math.floor(Math.random() * letters1.length)] // 随机生成符合第5至第8位的字符
  s += letters1[Math.floor(Math.random() * letters1.length)]
  s += letters1[Math.floor(Math.random() * letters1.length)]
  s += letters1[Math.floor(Math.random() * letters1.length)]
  s += letters2[Math.floor(Math.random() * letters2.length)] // 随机生成符合第9至第12位的字符
  s += letters2[Math.floor(Math.random() * letters2.length)]
  s += letters2[Math.floor(Math.random() * letters2.length)]
  s += letters2[Math.floor(Math.random() * letters2.length)]
  s += 1000 + Math.floor(Math.random() * 3000) // 随机生成符合最后4位的数
  return s
}

// 生成一个UUID
const getUUID = (len = 16) => {
  var chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("")
  var uuid = []
  var i
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * 32)]
  } else {
    var r
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-"
    uuid[14] = "4"
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16)
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join("")
}

//判断是什么端
// 0：手机 h5
// 1：pc web
// 2: 微信公众号网页
// 3：电脑软件app
// 4：uni 微信小程序
const isDesktop = () => {
  if (isUni()) {
    // #ifdef MP-WEIXIN
    console.log("这是微信小程序")
    return 4
    // #endif
  }
  let result = 0
  let ng = navigator.userAgent
  let detectDeviceType =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|Mobile|IEMobile|Opera Mini/i.test(
      ng
    )
      ? false
      : true
  var ua = window ? window.navigator.userAgent.toLowerCase() : ""
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    // 是微信客户端
    result = detectDeviceType ? 2 : 0
  } else {
    if (ng.indexOf("draw") != -1) {
      result = 3
    } else {
      result = detectDeviceType ? 1 : 0
    }
  }
  return result
}

// 获取当前时间格式
const formatTime = (type) => {
  var date = new Date(new Date().getTime())
  var YY = date.getFullYear() + "-"
  var MM =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-"
  var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
  var hh =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":"
  var mm = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
  var ss =
    ":" + (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds())
  if (type == 1) {
    return YY + MM + DD
  } else if (type == 2) {
    return YY + MM + DD + " " + hh + mm
  } else {
    return YY + MM + DD + " " + hh + mm + ss
  }
}

// 判断是否微信环境
const isWeiXin = () => {
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  var ua = window.navigator.userAgent.toLowerCase()
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if (
    ua.match(/MicroMessenger/i) == "micromessenger" &&
    ua.match(/wxwork/i) != "wxwork"
  ) {
    return true
  } else {
    return false
  }
}

const isUni = () => {
  try {
    if (uni) return 1
    return 0
  } catch (e) {
    return 0
  }
}

export { getEkey, getUUID, isDesktop, formatTime, isWeiXin, isUni }
