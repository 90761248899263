import {
  userGetLoginCodeApi,
  userGetLoginTokenApi,
  getVerifyCodeApi,
  phoneLoginApi,
} from "./api/login"
import config from "./config"
import { removeStorage, setStorage } from "./utils/storage"
import { isWeiXin } from "./utils"
import jsonJoin from "./utils/jsonJoin"

const login = {
  intervalTimer: null,
  paramsLogin: {
    temporary_id: null,
    source: config.source,
    device_code: config.device_code,
    soft_id: config.soft_id,
    ekey: config.ekey,
  },
  // 获取公众号登录二维码
  async getLoginCode() {
    return new Promise(async (resolve, reject) => {
      let codeUrl = null
      try {
        const res = await userGetLoginCodeApi()
        let { data } = res
        if (data.code == 0) {
          const loginData = data.data
          codeUrl = loginData.url
          this.paramsLogin.temporary_id = loginData.ewm_id
        }
        if (codeUrl)
          resolve({
            codeUrl: codeUrl,
          })
        reject({
          msg: "获取登录信息失败",
        })
      } catch (e) {
        reject({
          msg: "请求失败",
        })
      }
    })
  },
  // 登录轮询
  async loginPoll() {
    let overTime = 60
    const that = this

    // 获取登录的token
    const getLoginToken = () => {
      return new Promise(async (resolve) => {
        try {
          const res = await userGetLoginTokenApi(this.paramsLogin)
          const data = res.data
          if (data.code >= 300) {
            resolve({
              status: 0,
              msg: data.msg,
            })
          }
          resolve({
            status: 1,
            token: data.data.token,
          })
        } catch (e) {
          resolve({
            status: 0,
            msg: "请求失败",
          })
        }
      })
    }

    return new Promise((resolve, reject) => {
      that.intervalTimer = setInterval(async () => {
        if (overTime <= 0) {
          reject({
            code: 1000,
            msg: "二维码已过期，请重新获取",
          })
          clearInterval(that.intervalTimer)
        } else {
          overTime -= 2
          const res = await getLoginToken()
          if (res.status === 1 && res.token !== "") {
            clearInterval(that.intervalTimer)
            setStorage("token", res.token)
            resolve({
              token: res.token,
            })
          }
        }
      }, 2000)
    })
  },
  // 停止轮询二维码登录
  stopInterval() {
    clearInterval(this.intervalTimer)
  },
  // 获取手机号验证码
  async getPhoneVerifyCode(phone) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getVerifyCodeApi({
          phone: phone,
        })
        const data = res.data
        if (data.code >= 300) {
          reject({
            msg: data.msg,
          })
        }
        resolve({
          status: 1,
        })
      } catch (e) {
        reject({
          msg: "请求失败",
        })
      }
    })
  },
  async phoneLogin(phone, code) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await phoneLoginApi({
          phone: phone,
          code: code,
        })
        const data = res.data
        if (data.code >= 300) {
          reject({
            msg: data.msg,
          })
        }
        resolve({
          token: data.data.token,
        })
      } catch (e) {
        reject({
          msg: "请求失败",
        })
      }
    })
  },
  // 退出登录
  logOut() {
    removeStorage("token")
  },
  isWeiXinEnv() {
    return isWeiXin()
  },
  loginWeiXin(url, wechatLoginUrl) {
    let prm = {
      soft_id: config.soft_id,
      curr_ver: config.curr_ver,
      device_id: config.device_id,
      mac_code: config.mac_code,
      device_code: config.device_code,
      os: config.os,
      callback_page: encodeURIComponent(url),
      mp: config.mp,
      source: config.source,
      ekey: config.ekey,
    }
    const href = `${wechatLoginUrl}?${jsonJoin(prm)}`
    window.location.href = href
  },
}

export default login
