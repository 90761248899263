import { getStorage } from "../../utils/storage";

const http = {
  requestFun(options = {}) {
    if (getStorage("token")) {
      if (!options.header) {
        options.header = {
          Authorization: getStorage("token"),
        };
      } else {
        if (!options.header["Authorization"])
          options.header["Authorization"] = getStorage("token");
      }
    }
    //异步封装接口，使用Promise处理异步请求
    return new Promise((resolve, reject) => {
      // 发送请求
      uni
        .request({
          url: options.url || "", //接收请求的API
          method: options.method, //接收请求的方式,如果不传默认为GET
          data: options.data || {}, //接收请求的data,不传默认为空
          header: options.header,
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  post(url, options) {
    let data = {};
    if (!options) {
      data.url = this.addEkeyAndSoftId(url);
      data.method = "POST";
    } else {
      data.data = options;
      data.url = this.addEkeyAndSoftId(url);
      data.method = "POST";
    }
    return this.requestFun(data);
  },
  get(url, options) {
    let data = {};
    if (!options) {
      data.url = this.addEkeyAndSoftId(url);
      data.method = "GET";
    } else {
      data = options;
      data.url = this.addEkeyAndSoftId(url);
      data.method = "GET";
    }
    return this.requestFun(data);
  },
  addEkeyAndSoftId(url) {
    const unHaveParams = url.indexOf("?") === -1;
    if (unHaveParams) return url + `?ekey=${getStorage("ekey")}`;
    const unHaveEkeys = url.indexOf("ekey=") === -1;
    if (unHaveEkeys) return url + `&ekey=${getStorage("ekey")}`;
    return url;
  },
};

export default http;
