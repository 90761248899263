<template>
	<div class="app-mains">
		<transition name="fade-transform" mode="out-in">
		  <keep-alive >
		    <router-view :key="key" />
		  </keep-alive>
		</transition>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				
			}
		},
		computed: {
		  // cachedViews() {
		  //   return this.$store.state.tagsView.cachedViews
		  // },
		  key() {
		    return this.$route.path
		  }
		},
		methods:{
			
		}
	}
</script>

<style lang="scss">
	.app-mains{
		min-height: calc(100vh - 72px);
		position: relative;
		overflow: hidden;
		padding-left: 180px;
		padding-top: 72px;
		width: 1080px;
		margin: 0 auto;
	}
</style>
