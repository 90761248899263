const config = {
  device_id: null,
  mac_code: null,
  device_code: null,
  ekey: null,
  /* 路径携带的参数 */
  source: "default",
  mp: "",
  bdVid: "",
  strategy: "",
  /* 需要手动初始化配置的数据 */
  soft_id: "",
  curr_ver: "1.0.0.0",
  os: 1,
  wxLoginAndPayDomain: "",
  qnwProjectDomain: "",
}

export default config
